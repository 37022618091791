.dayroutine{
    width: 100%;
    // max-width: 50rem;
    height: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(9, 1fr);

    @include tab{
        grid-template-columns: 1fr;
    }
    @include mobile{
        grid-template-columns: 1fr;
    }
    @include mobile-small{
        grid-template-columns: 1fr;
    }


    &__day{
        width: 100%;
        height: 100%;
        background-color: aqua;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 2rem;
        padding: 2rem;
    }
}