.weekroutine{
    display: grid;
    // grid-template-rows: repeat(7, 1fr);

    @include tab{
        grid-template-columns: repeat(2, 1fr);
        width: 90%;
        margin: auto;
        gap: 1rem;
    }
}