.header{
    width: 100%;
    min-height: 7rem;
    height: 10vh;
    max-height: 10rem;
    background-color: rgb(12, 12, 12);
    color: white;
    padding: 1rem 2rem;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 3.5rem;
    
    @include mobile{
        justify-content: center;
        font-size: 2.5rem;
    }
    @include mobile-small{
        justify-content: center;
        font-size: 2.5rem;
    }
}