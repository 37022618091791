.wrapper {
  width: 100%;
  max-width: max(90rem, 75%);
  margin: auto;
  padding: 2%;
}
@media screen and (min-width: 0px) and (max-width: 360px) {
  .wrapper {
    padding: 4%;
  }
}
@media screen and (min-width: 361px) and (max-width: 768px) {
  .wrapper {
    padding: 4%;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .wrapper {
    padding: 3%;
  }
}
@media screen and (min-width: 1025px) and (max-width: 1920px) {
  .wrapper {
    padding: 2%;
  }
}

.row {
  display: flex;
  flex-direction: row;
}
.row--center {
  justify-content: center;
  align-items: center;
}
.row--center--x {
  justify-content: center;
}
.row--center--y {
  justify-content: center;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-size: inherit;
  font-family: inherit;
}

body {
  box-sizing: border-box;
  font-size: 1.6rem;
  font-family: sans-serif;
}

html {
  font-size: 62.5%;
}

ol, ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.dayroutine {
  width: 100%;
  height: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .dayroutine {
    grid-template-columns: 1fr;
  }
}
@media screen and (min-width: 361px) and (max-width: 768px) {
  .dayroutine {
    grid-template-columns: 1fr;
  }
}
@media screen and (min-width: 0px) and (max-width: 360px) {
  .dayroutine {
    grid-template-columns: 1fr;
  }
}
.dayroutine__day {
  width: 100%;
  height: 100%;
  background-color: aqua;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 2rem;
  padding: 2rem;
}

.header {
  width: 100%;
  min-height: 7rem;
  height: 10vh;
  max-height: 10rem;
  background-color: rgb(12, 12, 12);
  color: white;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 3.5rem;
}
@media screen and (min-width: 361px) and (max-width: 768px) {
  .header {
    justify-content: center;
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 0px) and (max-width: 360px) {
  .header {
    justify-content: center;
    font-size: 2.5rem;
  }
}

.periodcard {
  width: 100%;
  min-height: 10rem;
  height: 100%;
  background-color: aqua;
  background-image: linear-gradient(to bottom, #042c82, rgb(7, 69, 204), #2869f8);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}
.periodcard__text {
  font-size: 1.2rem;
}

.weekroutine {
  display: grid;
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .weekroutine {
    grid-template-columns: repeat(2, 1fr);
    width: 90%;
    margin: auto;
    gap: 1rem;
  }
}/*# sourceMappingURL=main.css.map */